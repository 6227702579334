<template>
  <div class="d-md-flex">
    <div class="me-2" style="min-width:2rem;">{{ di.json.order }}.</div>
    <i18n :path="di.transPath('label')" tag="label" for="tos"
      :class="['text-justify', { 'mx-md-4': consent.mode == 'first_consent' }]"
      style="text-align: justify; text-justify: inter-word;">
      <template #br>
        <br />
      </template>
      <template #information_sheet>
        <a :href="pisInfo.pis_path" target="_blank" v-t="di.transPath('linkLabel')" />
      </template>
      <template #blood_information_sheet>
        <a :href="pisInfo.blood_pis_path" target="_blank" v-t="di.transPath('linkLabel')" />
      </template>
      <template #pis_version_date>
        {{ i18nDate(pisInfo.pis_version_date, 'numeric') }}
      </template>
      <template #pis_version_no>{{ i18nNumber(pisInfo.pis_version, "version") }}</template>
      <template #url>
        <a :href="di.transPath('url')" target="_blank" v-t="di.transPath('url')" />
      </template>
    </i18n>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import lang from "~/mixins/lang.js";
export default {
  name: 'consent-label',
  mixins: [lang],
  props: {
    consent: {},
    di: {},
    errors: {}
  },
  computed: {
    pisInfo() {
      return this.consent.questionnaire.extraData.pis_info[this.consent.selectedSection.name]
    }
  },
  methods: {

  }
}
</script>