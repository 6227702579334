<template>
  <div :class="{ 'was-validated': record.wasValidated }">
    <!-- <consent-header :consent-section="di"></consent-header> -->
    <h5 class="display-5 mb-4">{{ di.label }}</h5>
    <card v-for="(category, key) in categorizedDataitems" :key="'category' + key" v-if="shouldDisplayCategory(key)"
      :title="getCategoryTitle(category.title)" head :body="false" class="mb-4">
      <div v-if="categoryHeaderCategory(key)" :is="categoryHeaderCategory(key)" :consentSection="key"></div>
      <list-group flush>
        <list-group-item v-for="dataitem in category.items" :key="'dataitem' + dataitem.name"
          v-if="shouldDisplay(dataitem)">
          <consent-item :consent="consent" :di="dataitem" :disabled="disabled" v-model="record[dataitem.name]"
            :errors="errors">
          </consent-item>
          <template v-for="child in dataitem.children">
            <collapse :show="shouldDisplay(child)">
              <consent-item :consent="consent" :di="child" :disabled="disabled" v-model="record[child.name]"
                :errors="errors">
              </consent-item>
            </collapse>
          </template>
        </list-group-item>
      </list-group>
      <div v-if="categoryFooterCategory(key)" :is="categoryFooterCategory(key)" :consentSection="key"></div>

    </card>
    <!-- <validate-alert :errorCount="errorCount()"></validate-alert> -->
    <br>
    <div class="clearfix">
      <div class="text-center col-xs-offset-4 col-xs-4">
        <div>
          <!-- <div class="btn btn-primary" @click="pushRecord" v-on:push="save">Save</div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import consentItem from "~/pages/questionnaires/consent/consent-item.vue";
import consentHeader from "~/pages/questionnaires/consent/consent-header.vue";
import consentFooter from "~/pages/questionnaires/consent/consent-footer.vue";
import bloodStudyHeader from "~/pages/questionnaires/consent/blood-study-header.vue";
import { groupBy, sortBy, orderBy } from "lodash";
import { calculateBrackets } from "~/plugins/validator.js"
export default {
  name: 'consent-form',
  components: { consentHeader, consentItem, consentFooter, bloodStudyHeader },
  mixins: [],
  methods: {
    shouldDisplay(dataitem) {
      var display = !dataitem.json.requires || calculateBrackets(dataitem.json.requires, this.record);
      if (display == false) {
        // this.currentRecord[dataitem.name] = null;
      }
      return display;
    },
    shouldDisplayCategory(catInt) {
      var show = false;
      for (var index in this.categorizedDataitems[catInt].items) {
        show |= this.shouldDisplay(this.categorizedDataitems[catInt].items[index]);
      }
      return show;
    },
    categoryHeaderCategory(catInt) {
      var path = 'questionnaires.consent.category.' + catInt + '.header_component';

      if (this.$te(path)) {
        return this.$t(path)
      }
    },
    categoryFooterCategory(catInt) {
      var path = 'questionnaires.consent.category.' + catInt + '.footer_component';

      if (this.$te(path)) {
        return this.$t(path)
      }
    },
    save() {
      console.log("pushed")
    },
    cancel() {
      // this.validate = false; // removed
    },
    getCategoryTitle(position) { // project
      var titles = {
        1: this.$t('questionnaires.consent.required_items'),
        2: this.$t('questionnaires.consent.optional_items'),
        3: this.$t('questionnaires.consent.blood_items')
      }
      return titles[position];
    } // project
  },
  created() {
    // this.$watch("record", function () {
    //   this.currentRecord = this.record.copy();
    //   this.currentRecord.id = null;
    //   // this.setupValidation(); // non existent function, features may need replacing
    //   // this.validate = false; // removed
    // }.bind(this),{"immediate":true});
  },
  mounted() {

  },
  data() {
    return {
      currentRecord: null,
    }
  },
  computed: {
    errors() {
      return this.record.errors
    },
    filteredDataitems() {
      var filteredDataitems = {}
      for (var diName in this.dataitems) {
        var di = this.dataitems[diName]
        if (di.json.parent) {
          var parent = this.dataitems[di.json.parent]
          if (!parent.children) parent.children = []
          if (!parent.children.includes(di)) parent.children.push(di)
        } else {
          filteredDataitems[diName] = di
        }
      }
      return filteredDataitems
    },
    orderedDataitems() {
      return sortBy(this.filteredDataitems, (di) => di.json.order);
    },
    groupedCategories() {
      return groupBy(this.orderedDataitems, (di) => di.json.category);
    },
    categorizedDataitems() {
      var categories = this.groupedCategories
      var categoriesArray = [];
      for (var title in categories) {
        categoriesArray.push({ title, items: categories[title] });
      }
      return categoriesArray
    },
  },
  props: {
    record: {
      type: Object
    },
    di: {
      type: Object
    },
    disabled: {
      type: Boolean
    },
    mode: {
      type: String
    },
    dataitems: {
      type: Object
    },
    consent: {
      type: Object
    }
  }

}
</script>
<style scoped>
.card {
  white-space: pre-line;
}
</style>