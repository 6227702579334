<template>
    <div class="card-header">
        <i18n :path="'questionnaires.consent.category.' + consentSection + '.header_content'">
            <template></template>
        </i18n>
    </div>
</template>
<script>
import questionnaireGettersMixin from '~/mixins/questionnaire_getters'
import { mapGetters } from 'vuex'
export default {
    mixins: [questionnaireGettersMixin],
    name: 'consent-header',
    props: {
        consentSection: {}
    },
}
</script>