<template>
    <div class="card-body p-1">
        <div class="alert alert-info p-2 mb-0">
            <p>
                {{ $t("questionnaires.consent.category.2.header_content") }}

            </p>
            <p>
                <a href="/assets/documents/NO/Samtykke 2_HPOS_blodprøver.pdf" target="_blank">
                    <fa icon="file-pdf" class="mr-2"></fa>
                    {{ $t("questionnaires.consent.category.2.pis_consent") }}
                </a>
            </p>
            <p>
                <i18n path="public.new_welcome.find_out_more.cards.blood_study.link.text" class="mt-auto">
                    <template #link>
                        <a :href="$t('public.new_welcome.find_out_more.cards.blood_study.link.url')"
                            v-t="'public.new_welcome.find_out_more.cards.blood_study.link.url'" target="_blank"></a>
                    </template>
                </i18n>
            </p>
        </div>
    </div>
</template>
<script>
import questionnaireGettersMixin from '~/mixins/questionnaire_getters'
import { mapGetters } from 'vuex'
export default {
    mixins: [questionnaireGettersMixin],
    name: 'blood-study-header',
    props: {
        consentSection: {},
    },
    computed: {
    }
}
</script>